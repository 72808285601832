/**
 * Used to assert as any. Currently used only for asserting datatable headers, because typescript embeddings(for example, `:headers="asAny(headers)"`) in vue template are not syntax-highlighted correctly by "Vue Language Features" extension.
 *
 * */
export const asAny = <T>(value: T) => value as any;

/**
 * @example
 * const value = null as string | null | undefined;
 * if (hasValue(value)) {
 *   value.length // Value is string here
 * }
 */
export const hasValue = <T>(value: T | null | undefined): value is T => {
	return value !== null && value !== undefined;
};

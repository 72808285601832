import { ValidGranularities } from "@/Reports/cubejs";
import { getLocaleText } from "@/i18n";
import {
	format,
	parseISO,
	parse,
	fromUnixTime,
	getUnixTime,
	differenceInSeconds,
	startOfISOWeek,
	endOfISOWeek,
	startOfMonth,
	endOfMonth,
	startOfDay,
	endOfDay,
	subDays,
	subMonths,
	startOfYear,
	endOfYear,
	subWeeks,
	subYears,
} from "date-fns";
import VueI18n, { TranslateResult } from "vue-i18n";
import { hasValue } from "../types/assert";

export const ISO_DATE_FORMAT = "yyyy-MM-dd";
export const ISO_TIME_FORMAT = "HH:mm:ss";
export const ISO_DATETIME_FORMAT = "yyyy-MM-dd HH:mm:ss";
export const MAX_TIME_CSHARP = 253402297199;

export function unixToTimeString(unix: number, locale: string | VueI18n.TranslateResult, skipConversion = false): string {
	const _unix = unix && new Date(unix).getFullYear() === 1970 && !skipConversion ? unix * 1000 : unix;
	return dateToTimeString(_unix, locale.toString());
}

export function dateToTimeString(value: Date | number, locale: string | VueI18n.TranslateResult): string {
	if (!value) return "";
	if (typeof value === "number") {
		value = new Date(value).getFullYear() === 1970 ? value * 1000 : value;
	}
	return format(value, locale.toString());
}

export function parseTimeString(value: string, format: string | null = null): Date | null {
	if (!value) return null;
	if (!format) return parseISO(value);
	else return parse(value, format, new Date());
}

export function unixToDate(unix: number): Date {
	return fromUnixTime(unix);
}

export function dateRangeToUnixRange(dateRange: [Date, Date]): [number, number] | null {
	const startDate = dateRange?.[0] ? dateToUnix(dateRange[0]) : null;
	const endDate = dateRange?.[1] ? dateToUnix(dateRange[1]) : null;
	const range = startDate && endDate ? ([startDate, endDate] as [number, number]) : null;
	return range;
}

export function dateToUnix(date: Date): number {
	return getUnixTime(date);
}

export function secondsSinceUnix(unix: number): number {
	return secondsSinceDate(unixToDate(unix));
}

export function secondsSinceDate(date: Date): number {
	return differenceInSeconds(new Date(), date);
}

export function dateRangeToReadableString(valueInternal: [Date, Date] | [number, number] | null) {
	if (!hasValue(valueInternal) || valueInternal.length !== 2) return "";
	return `${dateToTimeString(valueInternal[0], getLocaleText("LocalDate"))} - ${dateToTimeString(
		valueInternal[1],
		getLocaleText("LocalDate"),
	)}`;
}

export function secondsToDurationString(timespan: number | string) {
	if (isNaN(timespan as number)) return "";
	const timespanN = timespan as number;
	const seconds = Math.floor(timespanN % 60);
	const minutes = Math.floor((timespanN / 60) % 60);
	const hours = Math.floor(((timespanN - Math.floor(timespanN / 86400) * 86400) / 3600) % 60);
	const days = Math.floor(timespanN / 86400);

	const array: string[] = [];
	if (days !== undefined && days > 0) {
		array.push(`${days} ${getLocaleText("Days")}`);
	}
	if ((hours !== undefined && hours > 0) || days > 0) {
		array.push(`${hours} ${getLocaleText("Hours")}`);
	}
	if ((minutes !== undefined && minutes > 0) || hours > 0 || days > 0) {
		array.push(`${minutes} ${getLocaleText("MinutesShort")}`);
	}
	if ((seconds !== undefined && seconds >= 0) || minutes > 0 || hours > 0 || days > 0) {
		array.push(`${seconds} ${getLocaleText("SecondsShort")}`);
	}
	return array.join(" ");
}

/**
 * Converts seconds into a short duration string like "1 T. 10:33:08" or "00:14:07"
 */
export function secondsToShortDurationString(totalSeconds: number) {
	if (isNaN(totalSeconds)) return "";

	const seconds = Math.floor(totalSeconds % 60);
	const minutes = Math.floor((totalSeconds / 60) % 60);
	const hours = Math.floor(((totalSeconds - Math.floor(totalSeconds / 86400) * 86400) / 3600) % 60);
	const days = Math.floor(totalSeconds / 86400);

	const array: string[] = [];
	if (days !== undefined && days > 0) {
		array.push(`${days} ${getLocaleText("DaysAbbreviation")}.`);
	}
	array.push(`${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`);
	return array.join(" ");
}

export function readableStringFromGranularity(date: Date, granularity: ValidGranularities): string {
	const format = getLocaleText("LocalDate").toString();
	switch (granularity) {
		case "week": {
			const start = dateToTimeString(startOfISOWeek(date), format);
			const end = dateToTimeString(endOfISOWeek(date), format);
			return `${start} - ${end}`;
		}
		case "month": {
			const start = dateToTimeString(startOfMonth(date), format);
			const end = dateToTimeString(endOfMonth(date), format);
			return `${start} - ${end}`;
		}
		case "day":
		default:
			return dateToTimeString(date, format);
	}
}

export type Timespan = {
	name: string;
	start: () => Date;
	end: () => Date;
};

export const Timespans: Record<string, Timespan> = {
	TODAY: {
		name: "Timespans.TODAY",
		start: () => startOfDay(new Date()),
		end: () => endOfDay(new Date()),
	},
	YESTERDAY: {
		name: "Timespans.YESTERDAY",
		start: () => startOfDay(subDays(new Date(), 1)),
		end: () => endOfDay(subDays(new Date(), 1)),
	},
	LAST_SEVEN_DAYS: {
		name: "Timespans.LAST_SEVEN_DAYS",
		start: () => startOfDay(subDays(new Date(), 7)),
		end: () => endOfDay(new Date()),
	},
	LAST_THIRTY_DAYS: {
		name: "Timespans.LAST_THIRTY_DAYS",
		start: () => startOfDay(subDays(new Date(), 30)),
		end: () => endOfDay(new Date()),
	},
	THIS_WEEK: {
		name: "Timespans.THIS_WEEK",
		start: () => startOfISOWeek(new Date()),
		end: () => endOfISOWeek(new Date()),
	},
	THIS_MONTH: {
		name: "Timespans.THIS_MONTH",
		start: () => startOfMonth(new Date()),
		end: () => endOfMonth(new Date()),
	},
	PREVIOUS_MONTH: {
		name: "Timespans.PREVIOUS_MONTH",
		start: () => startOfMonth(subMonths(new Date(), 1)),
		end: () => endOfMonth(subMonths(new Date(), 1)),
	},
	LAST_THREE_MONTHS: {
		name: "Timespans.LAST_THREE_MONTHS",
		start: () => startOfMonth(subMonths(new Date(), 3)),
		end: () => endOfMonth(new Date()),
	},
	LAST_YEAR: {
		name: "Timespans.LAST_YEAR",
		start: () => startOfYear(subYears(new Date(), 1)),
		end: () => endOfYear(subYears(new Date(), 1)),
	},
	THIS_YEAR: {
		name: "Timespans.THIS_YEAR",
		start: () => startOfYear(new Date()),
		end: () => endOfYear(new Date()),
	},
	LAST_WEEK: {
		name: "Timespans.LAST_WEEK",
		start: () => startOfISOWeek(subWeeks(new Date(), 1)),
		end: () => endOfISOWeek(subWeeks(new Date(), 1)),
	},
};

export const removeTzFromIsoString = (v: string) => {
	if (v.endsWith("Z")) return v.slice(0, -1);
	if (v.length > 6 && (v[v.length - 3] === ":" || v[v.length - 6] === "+" || v[v.length - 6] === "-")) return v.slice(0, -6);
	throw new Error("Invalid ISO String");
};

export const offsetUnixTimestamp = (unixTimestampSeconds: number, siteOffset: number = 0) => {
	return unixTimestampSeconds + new Date().getTimezoneOffset() * 60 + siteOffset * 60;
};


import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Axios, { AxiosResponse } from "axios";
import API from "@/store/API";
import { ShiftProductionAreaDTO, ShiftDTO, SiteDTO, ActivityDTO } from "@/Commons/interfaces/restdto";

import { unixToDate, unixToTimeString } from "@/Commons/utils/timeUtils";
import { differenceInMilliseconds } from "date-fns/fp";
import { addDays, startOfDay } from "date-fns";
import { ROUTER_PARAM_KEYS } from "@/router";
import { namespace } from "vuex-class";

const ActivityLoadReport = () => import("@/Reports/ActivityLoad/ActivityLoadReport.vue");
const ZoneOccupancyReport = () => import("@/Reports/ZoneOccupancy/ZoneOccupancyReport.vue");
const Reports = namespace("Reports");
@Component({
	components: {
		ActivityLoadReport,
		ZoneOccupancyReport,
	},
})
export default class ShiftIcon extends Vue {
	@Prop({ required: true }) readonly site: SiteDTO;
	@Reports.Mutation setUpdateTimestamp: () => void;

	ROUTER_PARAM_KEYS = ROUTER_PARAM_KEYS;

	productionAreas: ShiftProductionAreaDTO[] = [];
	timeout = -1;
	dailyRefreshTimeout = -1;

	lastUpdate: Date = new Date(-1000);
	get dateList(): Date[] {
		return this.productionAreas
			.map((p) => p.Shifts.map((s) => [unixToDate(s.start), unixToDate(s.end)]).flat())
			.flat()
			.sort((a, b) => (a[0] <= b[0] ? -1 : 1));
	}

	get datesIndex(): number {
		return this.dateList.findIndex((date) => date >= this.lastUpdate);
	}

	get activeShifts(): ShiftDTO[] {
		return this.productionAreas.flatMap((data) =>
			data.Shifts.filter((s) => unixToDate(s.start) <= this.lastUpdate && unixToDate(s.end) >= this.lastUpdate),
		);
	}

	mounted() {
		this.fetchDates();
		this.setDailyRefresh();
	}

	beforeDestroy() {
		if (this.timeout != -1) {
			window.clearTimeout(this.timeout);
		}
		if (this.dailyRefreshTimeout != -1) {
			window.clearTimeout(this.dailyRefreshTimeout);
		}
	}

	fetchDates() {
		Axios.get(API.shiftManager.range, {
			params: {
				SiteId: this.site.id,
			},
		})
			.then((data: AxiosResponse<ShiftProductionAreaDTO[]>) => {
				this.productionAreas = data.data;
				this.lastUpdate = new Date();
			})
			.catch((e) => {
				window.console.error("Could not load Range");
			});
	}

	get dotColor(): string {
		if (this.productionAreas.length == 0) {
			return "grey";
		}
		if (this.activeShifts.length == 0) {
			return "error";
		}
		if (this.activeShifts.length != this.site.productionAreas?.length) {
			return "warning";
		}
		return "success";
	}

	get allActivities(): ActivityDTO[] {
		return this.site?.productionAreas?.flatMap((p) => p.activities) || [];
	}

	getActiveShift(prodAreaExternalName: string): ShiftDTO {
		return this.activeShifts.find((s) => s.id == prodAreaExternalName);
	}

	@Watch("site")
	siteChanged(site: SiteDTO) {
		this.fetchDates();
	}

	@Watch("datesIndex")
	dateIndexUpdated() {
		if (this.timeout != -1) {
			window.clearTimeout(this.timeout);
			this.timeout = -1;
		}
		this.timeoutFunction();
	}

	timeoutFunction() {
		if (this.datesIndex == -1 || this.datesIndex >= this.dateList.length) {
			return;
		}
		const msUntilNextUpdate = this.dateList[this.datesIndex].getTime() - new Date().getTime();
		this.timeout = window.setTimeout(() => {
			// changing lastUpdate triggers the entire chain of getters
			this.lastUpdate = new Date();
		}, msUntilNextUpdate);
	}

	setDailyRefresh() {
		// Don't update on first call
		if (this.dailyRefreshTimeout != -1) {
			this.fetchDates();
		}
		window.console.debug("Next Refresh of Shifts in", this.msUntilNextDay, "ms");
		this.dailyRefreshTimeout = window.setTimeout(this.setDailyRefresh, this.msUntilNextDay);
	}

	formatDate(unix: number) {
		return unixToTimeString(unix, this.$t("LocalTime").toString());
	}

	get msUntilNextDay(): number {
		return Math.abs(differenceInMilliseconds(new Date(), startOfDay(addDays(new Date(), 1))));
	}

	//#region
	activityLoadOpen = false;
	zoneOccupancyOpen = false;

	openZoneOccupancy() {
		this.zoneOccupancyOpen = true;
		this.setUpdateTimestamp();
	}
	openActivityLoad() {
		this.setUpdateTimestamp();
		this.activityLoadOpen = true;
	}
	//#endregion
}
